import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import moment from 'moment';
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import Swal from "sweetalert2";
import toastr from "toastr";
import Header from "../Layout/Header";
import NavBar from "../Layout/NavBar";
import ReactLoading from "react-loading";
import TextEditor from "./Partial/TextEditor";
import ImageUploader from "./Partial/SingleUpload";
import 'react-quill/dist/quill.snow.css';
import ToastHelper from "./Partial/toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

let tempImageView = '';
  const Press = () => {
  const [show, setShow]             = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [deleteId, setDeleteId]     = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClose           = () =>{
    setShowDelete(false);
    setDeleteId('');
  } 


  const handleDetailClose                 = () => setShowDetail(false);
  const handleDetailShow                  = () => setShowDetail(true);
  const handleCloseUpdate                 = () => setShowUpdate(false);
  const handleShowUpdate                  = () => setShowUpdate(true);
  const handleClose                       = () => setShow(false);
  const handleShow                        = () => setShow(true);
  const authToken                         = localStorage.getItem("access_token");
  const [posts, setPosts]                 = useState([]);
  const [images, setImages]               = useState("");
  const [singlePost, setSinglePost]       = useState("");
  const [imageUrl, setImageUrl]             = useState('');
  const [loading, setLoading]             = useState(false);
  const [title, setTitle]                 = useState();
  const [status, setStatus]               = useState('');
  const [description, setDescription]     = useState();
  const [isEdit, setIsEdit]               = useState(false);
  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    // setMultiCategory(value);
  };

  useEffect(() => {
    getPress();
  }, []);

  //GET PRESS DATA ----------------------------------------------
  const getPress= (id = 0) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_API}/api/press`;
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/press/${id}`;
    }
    const params = {
      latest:true
    }
    axios
      .get(url,  {
        params,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          setSinglePost(response.data.data);
          setIsEdit(response.data.data.id);
          setTitle(response.data.data.title);
          setImageUrl(response.data.data.image_url);
          setImages(response.data.data.image_url);
          setStatus(response.data.data.published);
          setDescription(response.data.data.description);
          setLoading(false);

        } else {
          setPosts(response.data.data);
          setLoading(false);

        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };

  // COLOUM FOR DATA TABLE ------------------------------------------
  const [columns] = useState([
    {
      name: "Title",
      // width: "120px",
      minWidth:"120px",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      // width: "180px",
      minWidth:"180px",
      cell: (row) => <div dangerouslySetInnerHTML={{ __html: `${row.description.substring(0, MAX_LENGTH)} ...`}} />,
      sortable: true,
    },
    
    {
      name: "Images",
      // width: "100px",
      minWidth:"100px",
      cell: (row) => (
        <div className="datatable_img_div">
      
              <div className="inside_img_div">
              {row.image_url?
                <img
                  src={process.env.REACT_APP_API + "/" + row.image_url}
                  width="50px"
                /> : '' }
              </div>
        </div>
      ),
    },
    {
      name: "Published Date",
      selector: (row) => moment(row.created_at).format('LL'),
      // width: "100px",
      minWidth:"100px",
      sortable: true,
      cell: (row) => <>{moment(row.created_at).format('LL')}</>,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      // width: "100px",
      minWidth:"100px",
      sortable: true,
      cell: (row) => <>{row.created_by}</>,
    },
    {
      name: "Status",
      selector: (row) => row.published,
      // width: "100px",
      minWidth:"100px",
      sortable: true,
      cell: (row) => (
        <div>
          {row.published == 1 ? <span class="text-success">Active</span> : <span class="text-danger">Inactive</span>}
        </div>
      ),
    },
    {
      name: "Action",
      // width: "100px",
      minWidth:"100px",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShow();
              getPress(row.id);
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              handleShowUpdate();
              getPress(row.id);
            }}
          >
            <i class="fa fa-edit text-primary"></i>{" "}
          </span>{" "}
          &nbsp;&nbsp;
          <span
            onClick={() => {
              setShowDelete(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{" "}
          </span>
        </div>
      ),
    },
  ]);

  // Create ---------------------------------------------------------
  const Add = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
      return;
    }
    setIsSubmitLoading(true);
    // var image_data_url = [];
    // images.map((u) => image_data_url.push(u.data_url));
    const payLoad = {
      title: title,
      published: status,
      description: description,
      image_url: images,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/press`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "Press has been created.", "success");
        setShow(false);
        handleClose();
        setIsSubmitLoading(false);
        getPress();
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setIsSubmitLoading(false);
        setLoading(false);
      });
  };

  //UPDATE PRESS -----------------------------------------------------
  const Update = (id) => {


    // alert(images);
    if (!id) {
      return false;
    }
    // var image_data_url = [];
    // images.map((u) => image_data_url.push(u.data_url));
    // console.log("images",images);
    setIsSubmitLoading(true);
    const payLoad = {
      title: title,
      published: status,
      description: description,
      image_url: images,
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/press/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        getPress();
        handleCloseUpdate();
        Swal.fire("Updated!", "Press has been Updated.", "success");
        tempImageView = '';
        setImages('');
        setImageUrl('');
        setIsSubmitLoading(false);
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        tempImageView = '';
        setImages('');
        setImageUrl('');
        setIsSubmitLoading(false);
        setLoading(false);
      });
  };

  // DELETE PRESS ----------------------------------------------------
  const deleteHandle = () => {
    if (deleteId == '') {
      return false;
    }
    setIsSubmitLoading(true);
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/press/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire("Deleted!", "Press has been deleted.", "success");
          getPress();
          setDeleteId('');
          setIsSubmitLoading(false);
          handleDeleteClose();
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          setDeleteId('');
          setIsSubmitLoading(false);
          handleDeleteClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setIsSubmitLoading(false);
        setLoading(false);
          setDeleteId('');
          handleDeleteClose();
      });
  };

  return (
    <>
        <div>
          <div className="wrapper Container">
            <div className="section Row">
              <div>
                <Header />
              </div>
              <div className="align-adj ">

                  <Container>
                    <Row >
                      <Col>
                        <button
                          type="button"
                          className="btn btn-info btn-create"
                          onClick={() => {
                            handleShow();
                            //loadingProcess();
                          }}
                        >
                          Create New
                        </button> 
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <DataTable
                          title="Press Lists"
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns}
                          data={posts}
                          progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          ignoreRowClick
                          selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  </Container>

                  {/* Add Modal Start */}
                  <Modal show={show} 
                    onHide={() => {
                      handleClose();
                      tempImageView = '';
                    }} 
                    className="modal-style">
                    <Modal.Header closeButton>
                      <Modal.Title>Create Press</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          <div>
                            <label class="labelForm">Title:</label>
                            <input
                              type="text"
                              placeholder="Press Title"
                              onChange={(e) => setTitle(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            <TextEditor setDescription={setDescription} item={""} className="text-editor"/>
                          </div>
                          <div>
                            <label class="labelForm">Status:</label>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="0">Choose Blog Status</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                          <div>
                            <label class="labelForm">Images:</label>
                            <ImageUploader setImageHandler={setImageHandler} />
                            {tempImageView != ''? <img src={tempImageView} alt="Preivew" width="100px"></img> : '' }
                            {show && tempImageView != '' ? <FontAwesomeIcon icon={faTrash} style={{color:'#ff0000', alignItems:'center'}} 
                              onClick={()=> {
                                tempImageView = ''
                                setImages('')
                              }}/> : '' }
                          </div>
                        </Form>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        disabled= {isSubmitLoading? true : false}
                        onClick={() => {
                          Add();
                          tempImageView = '';
                        }}
                      >
                        {isSubmitLoading? 'Loading...' : 'Create'}
                      </Button>
                      <Button variant="secondary"
                       onClick={() => {
                        handleClose(); 
                        tempImageView = '';
                      }}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Add Modal End */}

                  {/* Update Modal Start */}
                  <Modal show={showUpdate} onHide={handleCloseUpdate}>
                    <Modal.Header closeButton>
                      <Modal.Title>Update Press</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {loading ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          <div>
                            <label class="labelForm">Title:</label>
                            <input
                              type="text"
                              placeholder="Press Title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            <TextEditor setDescription={setDescription} item={singlePost.description} className="text-editor"/>
                          </div>
                          <div>
                            <label class="labelForm">Status:</label>
                            <select
                              className="inputForm form-control"
                              onChange={(e) => setStatus(e.target.value)}
                              value={status}
                            >
                              <option value="">Choose Blog Status</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                          <div>
                            <label class="labelForm">Images:</label>
                            <ImageUploader setImageHandler={setImageHandler} imageUrl={imageUrl} /> 
                              {/* {tempImageView != '' ?
                              <img src={tempImageView} alt="Preivew" width="100px"></img> : 
                              <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img>} */}
                              <span className="img-position">
                              {tempImageView == '' && imageUrl != '' ?  <>
                              <img src={process.env.REACT_APP_API + "/" + imageUrl} alt="Preivew" width="100px"></img>
                              <FontAwesomeIcon icon={faTrash} className="img-position-child" 
                              onClick={() =>{
                                tempImageView = '';
                                setImageUrl('');
                                setImages("");
                              }}/>
                              </> :(tempImageView == '' ?  '' :  
                              <><img src={tempImageView} alt="Preivew" width="100px"></img>
                              <FontAwesomeIcon icon={faTrash} className="img-position-child" 
                              onClick={() => {
                                tempImageView = '';
                                console.log('tempImage', tempImageView)
                                setImageUrl('');
                                console.log('setImageUrl', imageUrl)
                                setImages("");
                                console.log('setImages', images)
                              }}/>
                              </> )}
                              </span>
                              
                            {/* <ImageUploader setImageHandler={setImageHandler} item={singlePost.post_images}/> */}
                          </div>
                        </Form>
                      </div>
                    }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        disabled= {isSubmitLoading? true : false}
                        onClick={() => { 
                          tempImageView = '';
                          Update(singlePost.id);
                        }}
                      >
                        {isSubmitLoading? 'Loading...' : 'Update'}
                      </Button>
                      <Button variant="secondary" 
                       onClick={() => {
                        handleCloseUpdate();
                        tempImageView = '';}}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Update Modal End */}

                  {/* Detail View Modal */}
                  <Modal
                    show={showDetail}
                    onHide={handleDetailClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Detail Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {loading ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                           <div>
                                <div className="press_img_div">
                                  {singlePost.image_url?
                                <img
                                  src={process.env.REACT_APP_API + "/" + singlePost.image_url}
                                  width="70%"
                                />: ''}
                                 &nbsp;
                              </div>
                          </div>
                          <div>
                            <label>{singlePost.title}</label>
                          </div>
                          <div>
                            <label><div dangerouslySetInnerHTML={{ __html: singlePost.description}} /></label>
                          </div>
                          <div>
                            <label class="labelForm">Status:</label>
                            <label>{singlePost.published ==0? "Inactive" : "active"}</label>
                          </div>
                        </Form>
                      </div>
                    }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDetailClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Detail View Modal End */}

                    {/* Delete View Modal */}
                    <Modal
                    show={showDelete}
                    onHide={handleDeleteClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        disabled = {isSubmitLoading? true: false}
                        onClick={() => {
                          deleteHandle();
                          // handleDeleteClose();
                        }}
                      >
                        {isSubmitLoading? 'Loading...' : 'Delete'}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Delete View Modal End */}
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default Press;
