import React, { useState, useEffect, useMemo } from 'react'
import Select from 'react-select'
import countryList, {getNames} from 'react-select-country-list'
import '../../assets/css/style.css'
import { Col, Container, Row, Form } from 'react-bootstrap'
import 'font-awesome/css/font-awesome.min.css'
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment';
import ReactLoading from 'react-loading'
import DataTable from 'react-data-table-component'
import Header from '../Layout/Header'
import NavBar from '../Layout/NavBar'
import TextEditor from './Partial/TextEditor'
import ImageUploader from "./Partial/SingleUpload"; 
import axios from 'axios'
import ToastHelper from "./Partial/toast";
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2'
import toastr from 'toastr'
import 'react-quill/dist/quill.snow.css';
import CountrySelector from './Partial/CountrySeLector'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser} from "@fortawesome/free-solid-svg-icons";

let tempImageView = '';
const CreateCarrer = (props) => {
  const [show, setShow]             = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)


  const [deleteId, setDeleteId]     = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showDeletePrice, setShowDeletePrice] = useState(false);
  const handleDeleteClose           = () =>{
    setShowDelete(false);
    setDeleteId('');
  } 
  const handleDeletePriceClose       = () =>{
    setShowDeletePrice(false);
    setDeleteId('');
  } 


  const [showDetailCandidate, setShowDetailCandidate] = useState(false)

  const handleDetailCandidateClose  = () => setShowDetailCandidate(false)
  const handleDetailShowCandidate   = () => setShowDetailCandidate(true)

  const handleDetailClose           = () => setShowDetail(false)
  const handleDetailShow            = () => setShowDetail(true)
  const handleCloseUpdate           = () => setShowUpdate(false)
  const handleShowUpdate            = () => setShowUpdate(true)
  const handleClose                 = () => setShow(false)
  const handleShow                  = () => setShow(true)
  const authToken                   = localStorage.getItem('access_token')
  const [status, setStatus]         = useState(true)
  const  [name, setName] = useState('');
  const  [expireDate, setExpireDate] = useState('');
  const  [email, setEmail] = useState('');
  const  [phoneNumber, setPhoneNumber] = useState('');
  const  [file, setFile] = useState('');
  const [carrer, setCarrer] = useState([])
  const  [jobName, setJobName] = useState('');
  const  [carrerId, setCarrerId] = useState('');
  const [candidate, setCandidate] = useState([])
  const [visible, setVisible]           = useState(false);
  const [imageUrl, setImageUrl]           = useState();
  const [description, setDescription]     = useState();
  const [website, setWebsite]             = useState();
  const [isEdit, setIsEdit]               = useState(false);
  const [partner, setPartner]             = useState([]);
  const [singlePartner, setSinglePartner] = useState("");
  const [singleCarrer, setSingleCarrer] = useState('')
  const MAX_LENGTH = 200
  const [loading, setLoading] = useState(false)
  const [loadingId, setLoadingId] = useState(false)
  const [location, setLocation] = useState({value: '' ,label: ''})
  const [jobType, setJobType] = useState("Full Time")
  const options = useMemo(() => countryList().getData(), [])


  function loadingProcess() {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const changeCountryHandler = value => {
    setLocation(value);
    // console.log(value);
  }

  const setFileHandler = (e) => { 

    var reader = new FileReader();
    reader.onload = function() {
        var pdfFilevalue = reader.result;
        // console.log("answer pdfFilevalue", pdfFilevalue);
        setFile(pdfFilevalue);
      };
      var asd =reader.readAsDataURL(e.target.files[0]);
    //   console.log("file value",file);
       
  }

  useEffect(() => {
    getCarrer();
  }, [ ])

  //GET carrer DATA
  const getCarrer = (id = 0, callback=null) => {
   
    
    let url =''; 
    
    if (id != 0) {
      url = `${process.env.REACT_APP_API}/api/career/${id}`
      setLoadingId(true);
    } else {
      setLoading(true);
      url = `${process.env.REACT_APP_API}/api/career`
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (id != 0) {
          setSingleCarrer(response.data.data)
          setName(response.data.data.title)
          setDescription(response.data.data.description)
          setExpireDate(response.data.data.expiry_date)
          setJobType(response.data.data.job_type)
          setLocation({value:countryList().getValue(response.data.data.location), label:response.data.data.location})
          setStatus(response.data.data.status == "1" ? true : false)
          setCandidate(response.data.data.candidate);
          if(callback) {
            callback();
          }
        
        } else {
          setCarrer(response.data.data)
        }
        setLoading(false);
        setLoadingId(false);
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        setLoadingId(false);
      })
  }

    // //GET Candidate DATA
    const getCandidate = (id = 0) => {
      setLoadingId(true);
      let url = `${process.env.REACT_APP_API}/api/candidate`
      if (id != 0) {
        url = `${process.env.REACT_APP_API}/api/candidate/${id}`
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (id != 0) {
            setName(response.data.data.name)
            setJobName(response.data.data.career.title)
            setEmail(response.data.data.email)
            setPhoneNumber(response.data.data.phone)
            setFile(response.data.data.attachment_file)
            setLoadingId(false);
          } else {
            setCarrer(response.data.data)
            setLoadingId(false);

          }
          setLoadingId(false);
        })
        .catch((error) => {
          console.log(error)
          setLoadingId(false);
        })
    }

  // CREATE PARTNER 
  const CreateCarrer = () => {
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
      return;
    }
    
    const payLoad = {
      title: name,
      description: description,
      expiry_date: expireDate,
      job_type: jobType,
      location: location.label,
      status: status ? 1 : 0
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/career`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Created!", "New Jobs has been created.", "success");
        setShow(false);

        handleClose();
        getCarrer();
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // DELETE Job
  const deleteHandle = (id) => {
    if (deleteId == '') {
      return false;
    }
    const body = [`${deleteId}`];
    axios
      .delete(`${process.env.REACT_APP_API}/api/career/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.code === 200) {
          Swal.fire("Deleted!", "Job has been deleted.", "success");
          getCarrer();
          setDeleteId('');
          handleDeleteClose();
        } else if (response.data.code !== 200) {
          toastr.remove();
          toastr.options.positionClass = "toast-top-right";
          toastr.error(response.data.message);
          setDeleteId('');
          handleDeleteClose();
        }
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setDeleteId('');
        handleDeleteClose();
        setLoading(false);
      });
  };

    // DELETE Candidate
    const deleteHandleCandidate = (id) => {
      if (deleteId == '') {
        return false
      }
      const body = [`${deleteId}`]
      axios
        .delete(`${process.env.REACT_APP_API}/api/candidate/${deleteId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then(function (response) {
          if (response.data.code === 200) {
            Swal.fire('Deleted!', 'Candidate has been deleted.', 'warning')
            getCarrer(carrerId);
            setDeleteId('');
            handleDeletePriceClose();
          } else if (response.data.code !== 200) {
            toastr.remove()
            toastr.options.positionClass = 'toast-top-right'
            toastr.error(response.data.message)
            setDeleteId('');
            handleDeletePriceClose();
          }
        })
        .catch(function (error) {
          let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
        setDeleteId('');
        handleDeletePriceClose();
        })
    }



   //UPDATE PARTNER -----------------------------------------------------
   const Update = (id) => {

    // alert(images);
    if (!id) {
      return false;
    }
    
    const payLoad = {
      title: name,
      description: description,
      expiry_date: expireDate,
      status: status ? 1 : 0,
      job_type: jobType,
      location: location.label
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/career/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        getCarrer();
        handleCloseUpdate();
        Swal.fire("Updated!", "Carrer has been Updated.", "success");
        // window.location.href = "/blog";
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  // COLOUM FOR DATA TABLE
  const [columns] = useState([
    {
      name: 'Job Title',
      // width: '15%',
      minWidth:"120px",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.description ? <div dangerouslySetInnerHTML={{ __html: `${row.description.substring(0, MAX_LENGTH)} ...`}}/> : '',
      // width: "25%",
      minWidth:"180px",
      sortable: true,
    },
    {
      name: 'Applicants',
      selector: (row) => row.candidate.length,
      // width: "10%",
      minWidth:"100px",
      sortable: true,
      cell: (row) => (
        <a className="datatab-link"
          onClick={() => {
            // pricing(row.id);
            // getPricing();
            setCarrerId(row.id);
            getCarrer(row.id);
            setVisible(true);
          }}
        >
          <FontAwesomeIcon
                icon={faUser}
                aria-hidden="true"
                className="nav-icon"
              />{" "}{row.candidate.length}
        </a>
      ),
    },
    {
      name: 'Expire Date',
      selector: (row) => row.expiry_date,
      // width: '15%',
      minWidth:"100px",
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format('LL'),
      // width: '15%',
      minWidth:"100px",
      sortable: true,
    },
    {
      name: 'Action',
      // width: '15%',
      minWidth:"100px",
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShow()
              getCarrer(row.id)
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{' '}
          </span>{' '}
          &nbsp;&nbsp;
          <span onClick={() => {
              
              loadingProcess();
              getCarrer(row.id,()=> {
                handleShowUpdate();
              }); 
              }}><i class="fa fa-edit text-primary"></i> </span> &nbsp;&nbsp;
         <span
  	        onClick={() => {
              setShowDelete(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{' '}
          </span>
        </div>
      ),
    },
  ])

   // COLOUM FOR DATA TABLE
   const [columns_candidate] = useState([
    {
      name: 'Name',
      width: '15%',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'email',
      width: '10%',
      selector: (row) => row.email,
    },
    {
      name: 'Phone Number',
      selector: (row) => row.phone,
      width: '15%',
      sortable: true,
    },
    {
      name: 'PDF',
      selector: (row) => (
        <>
        {row.attachment_file &&
        <a href={process.env.REACT_APP_API + '/' + row.attachment_file} class="text-primary" target="_blank">
        <i class="fa fa-file-pdf-o text-primary"></i>{' '} View PDF</a> }
        {!row.attachment_file && 'No pdf file'}
      </>
      ),
      width: '20%',
      sortable: true,
    },
    {
      name: 'Action',
      width: '20%',
      cell: (row) => (
        <div>
          <span
            onClick={() => {
              handleDetailShowCandidate()
              getCandidate(row.id)
            }}
          >
            <i class="fa fa-eye text-secondary"></i>{' '}
          </span>{' '}
          &nbsp;&nbsp;
          <span
  	        onClick={() => {
              setShowDeletePrice(true);
              setDeleteId(row.id);
            }}
          >
            <i class="fa fa-trash-o text-danger"></i>{' '}
          </span>
        </div>
      ),
    },
  ])


  return (
    <div class="wrapper">
    <div class="section">
      <div>
        <Header />
      </div>
      <div class="align-adj">
        <div>
          <Container>
                  <Row>
                    <Col>
                      <button
                        type="button"
                        class="btn btn-info btn-partner"
                        onClick={() => {
                          loadingProcess();
                          handleShow();
                        }}
                      >
                        Create New Job
                      </button>
                    </Col>
                  </Row>
                  <Row>
                  <Col className="mt-3">
                        <DataTable
                          title="Job Lists"
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns}
                          data={carrer}
                          progressPending={loading}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          ignoreRowClick
                          selectableRows
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                      </Row>
                      {visible == true ? (
                    <Row>
                      <Col className="mt-3">
                          
                        <DataTable
                          title={'Candidate Lists'}
                          defaultSortFieldId={1}
                          pagination
                          highlightOnHover
                          columns={columns_candidate}
                          data={candidate}
                          ignoreRowClick
                          progressPending={loadingId}
                          progressComponent={	<ReactLoading
                            type={"cylon"}
                            color={"#2398ff "}
                            height={60}
                            width={80}
                          />}
                          dense
                          paginationPerPage={10}
                          paginationRowsPerPageOptions={[10, 30, 50, 100]}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                      </Container>
               {/* Add Modal Start */}
               <Modal show={show} 
                onHide={() => {
                  handleClose();
                  tempImageView = '';
                }} >
                  <Modal.Header closeButton>
                    <Modal.Title>Create New Job</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                      <div className="create-form">
                        <div>
                          <label class="labelForm">Job Title:</label>
                          <input
                              type="text"
                              placeholder="Job Title"
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                        </div>
                        <div>
                            <label class="labelForm">Description:</label>
                            <TextEditor setDescription={setDescription} item={""} className="text-editor"/>
                          </div>
                        <div>
                        <div>
                          <label class="labelForm">Job Type</label>
                          <select value={jobType} onChange={(e) => setJobType(e.target.value)} className="inputForm form-control" required>
                              <option value="">Select Type</option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                              <option value="Contract">Contract</option>
                              <option value="Remote">Remote</option>
                              <option value="Freelance">Freelance</option>
                          </select>
                        </div>
                        <div>
                          <label class="labelForm">Location</label>
                          <Select options={options}  onChange={changeCountryHandler} />
                          {/* <input
                              placeholder="Location"
                              onChange={(e) => setLocation(e.target.value)}
                              className="inputForm form-control"
                              required
                            /> */}
                        </div>
                        <div>
                          <label class="labelForm">Expire Date:</label>
                          <input
                              type="date"
                              placeholder="Expire Date"
                              onChange={(e) => setExpireDate(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                        </div>
                        <div>
                          <label class="labelForm">Status</label>
                          <input
                              type="checkbox"
                              onChange={(e) => setStatus(!status)}
                              checked={status}
                              // value={status}
                              // required
                            />
                        </div>
                        </div>
                      </div>
                      </Form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" 
                     onClick={() => {
                      handleClose(); 
                      tempImageView = '';
                    }}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                      CreateCarrer();
                      tempImageView = '';
                    }}>
                      Create
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Add Modal End */}

                {/* Update Modal Start */}
                <Modal show={showUpdate} 
                onHide={() => {
                  handleCloseUpdate();
                  tempImageView = '';
                  }}>
                    <Modal.Header closeButton>
                      <Modal.Title>Update Job</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                          <div>
                            <label class="labelForm">Job Title:</label>
                            <input
                              type="text"
                              placeholder="Job Title"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Expire Date:</label>
                            <input
                              type="text"
                              placeholder="Press Title"
                              value={expireDate}
                              onChange={(e) => setExpireDate(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Description:</label>
                            <TextEditor setDescription={setDescription} item={singleCarrer.description} className="text-editor"/>
                          </div>
                          <div>
                          <label class="labelForm">Job Type</label>
                          <select value={jobType} onChange={(e) => setJobType(e.target.value)} className="inputForm form-control" required>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                              <option value="Contract">Contract</option>
                              <option value="Remote">Remote</option>
                              <option value="Freelance">Freelance</option>
                          </select>
                        </div>
                        <div>
                          <label class="labelForm">Location</label>
                          <Select options={options} value={location} onChange={changeCountryHandler} />
                          {/* <input
                              placeholder="Location"
                              onChange={(e) => setLocation(e.target.value)}
                              value={location}
                              className="inputForm form-control"
                              required
                            /> */}
                        </div>
                        <div>
                          <label class="labelForm">Status</label>
                          <input
                              type="checkbox"
                              onChange={(e) => setStatus(!status)}
                              checked={status}
                              value={status}
                              required
                            />
                        </div>
                        </Form>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => {
                          Update(singleCarrer.id);
                          tempImageView = '';
                        }}
                      >
                        Update
                      </Button>
                      <Button variant="secondary" 
                       onClick={() => {
                        handleCloseUpdate();
                        tempImageView = '';}}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Update Modal End */}

                {/* View Modal Start */}
                <Modal show={showDetail} onHide={handleDetailClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Partner Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {loadingId ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                  <div class="modal-body">
                  <Form
                          className="needs-validation create-form"
                          noValidate
                        >
                      <div className="create-form">
                      <div>
                        
                          </div>
                        <div>
                          <label class="labelForm">Job Title:</label>
                          <label>{singleCarrer.title}</label>
                        </div>
                        <div>
                          <label class="labelForm">Expire Date</label>
                          <label>{singleCarrer.expiry_date}</label>
                        </div>
                        <div>
                          <label class="labelForm">Description:</label>
                          <label><div dangerouslySetInnerHTML={{ __html: singleCarrer.description}} /></label>
                        </div>
                      </div>
                      </Form>
                    </div>
}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleDetailClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* View Modal End */}

                    {/* View Candidate Modal Start */}
                    <Modal show={showDetailCandidate} onHide={handleDetailCandidateClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>application Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {loading ?
                    <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :
                    <div class="modal-body">
                      <Form className="needs-validation create-form" noValidate>
                        <div className="create-form">
                          <div className="press_img_div">
                            {/* <img
                              src={
                                process.env.REACT_APP_API +
                                '/' +
                                singleCarrer.logo
                              }
                              width="70%"
                            /> */}
                            &nbsp;
                          </div>
                          {/* <div>
                            <label class="labelForm">mobilePlan:</label>
                            <label>{singleCarrer.name}</label>
                          </div> */}
                          <div>
                            <label class="labelForm">Job Title:</label>
                            <label>
                              {jobName && jobName}
                            </label>
                          </div>
                          <div>
                            <label class="labelForm">Name:</label>
                            <label>
                              {name && name}
                            </label>
                          </div>
                          <div>
                            <label class="labelForm">Email:</label>
                            <label>
                              {email && email}
                            </label>
                          </div>
                          <div>
                            <label class="labelForm">Contact Number:</label>
                            <label>
                              {phoneNumber && phoneNumber}
                            </label>
                          </div>
                      
                          <div>
                         
                              <a href={ process.env.REACT_APP_API +
                                '/' + singleCarrer.pdf} target="_blank" className="text-primary"> 
                                <i class="fa fa-file-pdf-o"></i> view PDF</a>
                          </div>
                        </div>
                      </Form>
                    </div>
}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleDetailCandidateClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* View Candidate Modal End */}

                  {/* DeleteView Modal */}
                  <Modal
                    show={showDelete}
                    onHide={handleDeleteClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandle();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Delete Modal End */}

                  {/* DeleteView Modal */}
                  <Modal
                    show={showDeletePrice}
                    onHide={handleDeletePriceClose}
                    className="modal-dialog-centered"
                  >
                    <Modal.Header style={{backgroundColor: '#d1193e'}} closeButton>
                      <Modal.Title ><span style={{color: '#fff'}}>DELETE</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                     <span style={{color: '#ee3e38'}}> Are you sure? You want to delete this data!!</span>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeletePriceClose}>
                        cancel
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteHandleCandidate();
                          // handleDeleteClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* Delete Modal End */}

                </div>
            </div>
          </div>
          <div>
            <NavBar />
            <ToastContainer />
          </div>
        </div>
  )
}
export default CreateCarrer
