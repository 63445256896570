import React, { useEffect, useState, setState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import DataTable from "react-data-table-component";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ToastHelper from "../Partial/toast";
import Header from "../../Layout/Header";
import NavBar from "../../Layout/NavBar";
import ReactLoading from "react-loading";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

  let tempImageView = '';
  const EditICE = (props) => {

  let url_id = (props.match && props.match.params && props.match.params.id && props.match.params.id > 0) ? props.match.params.id : 0;
  let history = useHistory();

  const authToken                         = localStorage.getItem("access_token");
  const [images, setImages]               = useState([]);
  const [loading, setLoading]             = useState(false);
  const [name, setName]                   = useState('');
  const [rental, setRental]               = useState('');
  const [co2, setCo2]                     = useState('');
  const [patrol, setPatrol]               = useState('');
  const [fuel, setFuel]                   = useState('');
  const MAX_LENGTH = 200;

  const handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
  };

    //GET ICE VEHICLE DATA ----------------------------------------------
    const getIceVehicle= ( ) => {
        let id = url_id;
        setLoading(true);
        let url = `${process.env.REACT_APP_API}/api/ice_vehicles/${id}`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((response) => {
              setName(response.data.data.name);
              setRental(response.data.data.rental_price);
              setCo2(response.data.data.co2_status);
              setPatrol(response.data.data.petrol_capacity);
              setFuel(response.data.data.fuel_efficiency);
              setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);

          });
      };

  useEffect(() => {
    getIceVehicle();
  }, []);

  const setImageHandler = (images) => {
    tempImageView = images;
    setImages(images);
  };


  // Update ---------------------------------------------------------
  const Update = () => {
    let id = url_id;
    var form = document.getElementsByClassName("needs-validation");
    if (form[0].checkValidity() === false) {
      form[0].classList.add("was-validated");
    }
    
     if(name == '' || rental == '' || co2 == '') {
         if(name == '') {
            ToastHelper.toast("Name is required!", null, 'error');
         }
         if(rental == '') {
            ToastHelper.toast("rental is required!", null, 'error');
         }
         if(co2 == '') {
            ToastHelper.toast("co2 status is required!", null, 'error');
         }
         return;
     }
    setLoading(true);
    const payLoad = {
        name : name,
        rental_price: rental,
        co2_status: co2,
        petrol_capacity: patrol,
        fuel_efficiency: fuel
    };
    axios
      .put(`${process.env.REACT_APP_API}/api/ice_vehicles/${id}`, payLoad, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(function (response) {
        Swal.fire("Updated!", "Vehicle has been Updated.", "success");
        // console.log(response);
        setLoading(false);
        history.push('/ice-vehicle');
      })
      .catch(function (error) {
        let errorData = ' ';
        for (const [key, value] of Object.entries(error.response.data.error)) {
          errorData = `${value}`;
          ToastHelper.toast(errorData, null, 'error');
        }
        setLoading(false);
      });
  };

  return (
    <>
        <div>
          <div class="wrapper">
            <div class="section">
              <div>
                <Header />
              </div>
              <div class="align-form">
                <div className="card-form">
                      <h4>Edit ICE Vehicle</h4>
                      <div class="modal-body">
                        <Form
                          className="needs-validation create-form"
                          noValidate
                        >{loading ?
                          <Row className="d-flex justify-content-center align-items-center">
                          <ReactLoading
                          type={'cylon'}
                          color={'#2398ff '}
                          height={60}
                          width={80}
                        /></Row> :<>
                          <div>
                            <label class="labelForm">Name</label>
                            <input
                              type="text"
                              placeholder="ice vehicle Name"
                              value={name && name}
                              onChange={(e) => setName(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">rental price</label>
                            <input
                              type="number"
                              step={"0.001"}
                              placeholder="rental price"
                              value={rental && rental}
                              onPaste={(e) =>{
                                let data = e.clipboardData.getData('text/plain')
                                if(data.includes('e') || data.includes('E')) {
                                  e.preventDefault();
                                }
                              }}
                              onKeyDown={(e) => {
                                if(e.key == 'e' || e.key == 'E') {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => setRental(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">CO2 status</label>
                            <input
                              type="number"
                              step={"0.001"}
                              value={co2 && co2}
                              placeholder="CO2 status"
                              onPaste={(e) =>{
                                let data = e.clipboardData.getData('text/plain')
                                if(data.includes('e') || data.includes('E')) {
                                  e.preventDefault();
                                }
                              }}
                              onKeyDown={(e) => {
                                if(e.key == 'e' || e.key == 'E') {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => setCo2(e.target.value)}
                              className="inputForm form-control"
                              required
                            />
                          </div>
                          <div>
                            <label class="labelForm">Petrol Capacity</label>
                            <input
                              type="number"
                              step={"0.001"}
                              value={patrol && patrol}
                              placeholder="Petrol Capacity"
                              onPaste={(e) =>{
                                let data = e.clipboardData.getData('text/plain')
                                if(data.includes('e') || data.includes('E')) {
                                  e.preventDefault();
                                }
                              }}
                              onKeyDown={(e) => {
                                if(e.key == 'e' || e.key == 'E') {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => setPatrol(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          <div>
                            <label class="labelForm">Fuel Eficiency</label>
                            <input
                              type="number"
                              step={"0.001"}
                              value={fuel && fuel}
                              placeholder="Fuel Eficiency"
                              onPaste={(e) =>{
                                let data = e.clipboardData.getData('text/plain')
                                if(data.includes('e') || data.includes('E')) {
                                  e.preventDefault();
                                }
                              }}
                              onKeyDown={(e) => {
                                if(e.key == 'e' || e.key == 'E') {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => setFuel(e.target.value)}
                              className="inputForm form-control"
                            />
                          </div>
                          </>}
                          <Button
                        variant="primary"
                        className="btn-adjust-content mt-3"
                        onClick={() => {
                          Update();
                        }}
                        disabled = {loading? true : false}
                      >
                         
                        {loading == false ? 'Submit' : 
                        <div className="btn-adjust-content"> LOADING &nbsp;
                            <ReactLoading
                                type={"cylon"}
                                color={"#FFF"}
                                height='80%'
                                width='40px'
                                />
                             </div> }
                      </Button>
                        </Form>
                      </div>
                      
                </div>
              </div>
            </div>
            <div>
              <NavBar />
              <ToastContainer />
            </div>
          </div>
        </div>
    </>
  );
};

export default EditICE;
